<template>
  <div>
    <div class="d-flex">
      <div class="d-flex py-10 relative">
        <v-icon medium class="pl-10 absolute" @click="onClickPrevIcon">
          $vuetify.icons.prevIcon
        </v-icon>
      </div>
      <span
        class="d-flex justify-center gray900--text font-g14-44 font-weight-700 pt-10 ma-auto"
        >인재채용</span
      >
    </div>
    <div class="pt-10">
      <iframe
        src="https://cellook.career.greetinghr.com/"
        style="display: block; width: 100%; height: 100vh"
        frameborder="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FooterRecruit',
  computed: {
    ...mapGetters('ProfileStore', ['getProfile']),
  },
  methods: {
    onClickPrevIcon() {
      this.$router.back()
    },
  },
}
</script>
<style lang="scss" scoped></style>
